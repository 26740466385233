import { React, useState, useEffect, Fragment } from "react";
import { useShowController, SimpleShowLayout, TextField, EmailField, useDataProvider } from 'react-admin';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { TotalProcessingBill } from "./TotalProcessingBill";

const MyShow = props => {

    const dataProvider = useDataProvider();

    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        loaded, // boolean that is false until the record is available
        loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
        version, // integer used by the refresh feature
    } = useShowController(props);

    const [open, setOpen] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [currentPaymentToConfirm, setCurrentPaymentToConfirm] = useState({});
    const [openTpDialog, setOpenTpDialog] = useState(false);
    const [snackContent, setSnackContent] = useState('');

    const [invoices, setInvoices] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [totalProcessingPayments, setTotalProcessingPayments] = useState([]);

    useEffect(() => {
        var invoices = record?.invoices?.sort((a, b) => new Date(b.date) - new Date(a.date));
        setInvoices(invoices ? invoices : []);
        var alerts = record?.alerts?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAlerts(alerts ? alerts : []);

    }, [record]);

    useEffect(() => {
        var tpPayments = record?.totalProcessingPayments?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setTotalProcessingPayments(tpPayments ? tpPayments : []);
    }, [record]);

    const refillPayment = (paymentItem) => {
        dataProvider.getCustom('Admin/Refund', { id: paymentItem.id })
            .then((data) => {
                if (data.reason) {
                    openSnackBar('Error while refund ' + data.reason);
                } else {
                    openSnackBar('Refund successfull');
                }

            })
            .catch((e) => {
                openSnackBar('Error while refund ' + e.reason);
            });
    }


    const downloadTotalProcessingInvoice = (id) => {
        console.log("Donwload")
        const html2pdf = require('html2pdf.js');

        var element = document.getElementById("tpinvoice-" + id);

        html2pdf().from(element).set({
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                dpi: 192,
                scale: 5,
                letterRendering: true,
                useCORS: true
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            margin: [0, 0, 0, 0],
        }).toPdf().save("Facture-" + id + ".pdf");
    }

    const openSnackBar = (content) => {
        setSnackContent(content);
        setOpen(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackContent("");
    };

    const getSubscriptionStatus = (statusNumber) => {
        switch (statusNumber) {
            case 0:
                return "Essai";
            case 1:
                return "En cours";
            case 2:
                return "Retard de paiement";
            case 3:
                return "Annulé par opérateur";
            case 4:
                return "Annulé pour retard de paiement";
            case 5:
                return "Annulé par le client";
            default:
                return statusNumber;
        }
    }

    const revokeSubscription = () => {
        dataProvider.getCustom('Admin/Revoke', { id: record.subscription.id })
            .then((data) => {
                if (data.reason) {
                    openSnackBar('Erreur lors de la révoquation de la soucription ' + data.message);
                } else {
                    openSnackBar('Souscription révoquée et email de confirmation envoyé au client.');
                }
            })
            .catch((e) => {
                openSnackBar('Erreur lors de la révoquation de la soucription ' + e);

            });
    }

    const resendPassword = () => {
        dataProvider.getCustom(`Admin/ResetPassword?email=${record.email}`)
            .then(({ data }) => {
                openSnackBar(`Le nouveau mot de passe a été envoyé à ${record.email}`);
            })
            .catch((e) => {
                openSnackBar('Erreur lors de l\'envoi du nouveau mot de passe ' + e);

            });
    }

    const handleOpenDialog = (paymentItem) => {
        setCurrentPaymentToConfirm(paymentItem);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const confirmRefillPayment = (paymentItem) => {
        refillPayment(paymentItem);
        handleCloseDialog();
    }

    function getState(state) {
        var stateStr = "";
        switch (state) {
            case 0:
                stateStr = "Active";
                break;
            case 1:
                stateStr = "Désactivé par utilisateur"
                break;
            case 2:
                stateStr = "Expirée"
                break;

        }
        return stateStr;
    }

    return (
        <div>
            <h1>{record?.uid}</h1>

            <h2>Détails de l'abonnement</h2>
            <Grid container direction="row">

                <Grid item xs={6} container direction="column">
                    <Grid item>
                        Nom : {record?.name}
                    </Grid>
                    <Grid item>
                        Client : {record?.email}
                    </Grid>
                    <Grid item>
                        Téléphone : {record?.phoneNumber}
                    </Grid>
                    <Grid item>
                        Adresse : {record?.address} {record?.address ? "," : ""}  {record?.postalCode}  {record?.city}
                    </Grid>
                    <Grid item>
                        Crée le : {record && record.createdAt ? new Date(record.createdAt + "Z").toLocaleString() : ''}
                    </Grid>
                    <Grid item>
                        ID : {record?.id}
                    </Grid>
                    <Grid item>
                        Statut : <strong>{getSubscriptionStatus(record?.subscription?.status)}</strong>
                    </Grid>
                    <br />
                    <br />
                    <Grid item>
                        Origin : {record?.origin}
                    </Grid>
                    <Grid item>
                        Referrer : {record?.referrer}
                    </Grid>
                    <Grid item>
                        IP : {record?.ip}
                    </Grid>
                </Grid>

                <Grid item>
                    {!(record?.subscriptionTotalProcessing?.status.startsWith("canceled")) ?
                        <Button variant="contained" color="primary" onClick={revokeSubscription}>
                            Révoquer l'abonnement
                        </Button>
                        : <></>
                    }
                    <Button variant="contained" color="primary" style={{ marginLeft: "15px" }} onClick={resendPassword}>
                        Renvoyer mot de passe
                    </Button>
                </Grid>
            </Grid>
            <h2>Factures</h2>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Montant</TableCell>
                            <TableCell align="center">Statut</TableCell>
                            <TableCell align="center">Numéro de facture</TableCell>
                            <TableCell align="center">Date du paiement</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{row.amount / 100} €</TableCell>
                                <TableCell align="center">Payée</TableCell>
                                <TableCell align="center">{row.invoiceNumber}</TableCell>
                                <TableCell align="center">{row.date && new Date(row.date).toLocaleString()}</TableCell>
                                <TableCell align="center">
                                    <IconButton color="primary" component="span" onClick={() => downloadTotalProcessingInvoice(row.invoiceNumber)} >
                                        <GetAppIcon />
                                    </IconButton>
                                </TableCell>
                                <Box style={{ display: "none" }}>
                                    <TotalProcessingBill invoice={row} />
                                </Box>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {
                record?.payments && record?.payments.length > 0 &&
                <>
                    <h2>Payments</h2>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Payment Id</TableCell>
                                    <TableCell align="center">Due date</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {record?.payments.map((paymentItem, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{paymentItem.amount / 100} €</TableCell>
                                        <TableCell align="center">{paymentItem.status}</TableCell>
                                        <TableCell align="center">{paymentItem.id}</TableCell>
                                        <TableCell align="center">{paymentItem.requestedOn ? new Date(paymentItem.requestedOn).toLocaleString() : ''}</TableCell>
                                        <TableCell align="center">
                                            {paymentItem.status != 8 && paymentItem.status != "Declined" &&
                                                <>
                                                    <Button color="primary" onClick={() => handleOpenDialog(paymentItem)}>Refund</Button>
                                                    <Dialog
                                                        open={openDialog}
                                                        onClose={handleCloseDialog}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-title">Refund</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                Are you sure you want to refund {currentPaymentToConfirm.amount / 100} €
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleCloseDialog} color="primary">
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={() => confirmRefillPayment(currentPaymentToConfirm)} color="primary" autoFocus>
                                                                Refund
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
            {
                alerts && alerts.length > 0 && <>
                    <h2>Alertes</h2>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Préfecture</TableCell>
                                    <TableCell align="center">Service</TableCell>
                                    <TableCell align="center">Motif</TableCell>
                                    <TableCell align="center">Notif. Envoyées</TableCell>
                                    <TableCell align="center">Etat</TableCell>
                                    <TableCell align="center">Heure de création</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alerts.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{row.appointment.prefecture.city} </TableCell>
                                        <TableCell align="center">{row.appointment.servicePrefecture.name}</TableCell>
                                        <TableCell align="center">{row.appointment.motifPrefecture.name}</TableCell>
                                        <TableCell align="center">{row.notificationSentCount}</TableCell>
                                        <TableCell align="center">{getState(row.state)}</TableCell>
                                        <TableCell align="center">{row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </>
            }
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar} message={snackContent} autoHideDuration={6000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} action={
                <Fragment>
                    <Button color="secondary" size="small" onClick={handleCloseSnackBar}>
                        OK
                    </Button>
                </Fragment>
            } />
        </div>
    );
}

export const ClientShow = (props) => (
    <MyShow {...props}>
        <SimpleShowLayout>
            <TextField source="uid" />
            <TextField source="name" />
            <EmailField source="email" />
        </SimpleShowLayout>
    </MyShow>
);